import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/layout"
import Header from "../components/Header/header"
import SEO from "../components/seo"
import "../assets/sass/theme/pages/terms.scss"
import CtaBanner from "../components/CtaBanner/CtaBanner"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Header isHeaderSolid />
    <main className="pp with-spacing">
      <div className="page-width">
        <h1 className="title">Privacy Policy</h1>
        <p>Insert privacy policy content.</p>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </main>
    <CtaBanner />
  </Layout>
)

export default PrivacyPage
