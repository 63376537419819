import React from "react"
import { Link } from "gatsby"

import "./cta-banner.scss"

const CtaBanner = (props) => (
    <div className="cta-banner">
        <div className="page-width">
            <p>{props.ctaText}</p>
            <Link to="/contact/" className="button--brand button cta-banner__button ripple">{props.buttonText}</Link>
        </div>
    </div>
)

export default CtaBanner

// If the parent component doesn't specify a `buttonText` prop, then
// the value of `Get in touch` will be used.
CtaBanner.defaultProps = {
    ctaText: "Want More Information About Our Services?",
    buttonText: "Get in touch"
}
